import { useCallback, useEffect } from 'react';

/**
 * Triggers a callback when an ESC key press is registered.
 *
 * @param{function} callback - the callback function to call
 */
export const useEscPress = (callback) => {
  const escHandler = useCallback(
    (event) => {
      if (event.key === 'Escape') callback();
    },
    [callback],
  );

  useEffect(() => {
    window.addEventListener('keydown', escHandler);

    return () => {
      window.removeEventListener('keydown', escHandler);
    };
  }, [escHandler]);
};

export default useEscPress;
