import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/index.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./ReplicaLLSub-Regular.woff2\",\"fallback\":[\"system-ui\",\"sans-serif\"]}],\"variableName\":\"replica\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/index.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./ReplicaMonoLLSub-Regular.woff2\",\"fallback\":[\"monospace\"]}],\"variableName\":\"replicaMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/index.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./MDSystem-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"./MDSystem-Semibold.woff2\",\"weight\":\"600\"}],\"fallback\":[\"system-ui\",\"sans-serif\"]}],\"variableName\":\"mdSystem\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/app/src/app/breakpoints.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/app/src/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/app/src/app/variables.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/AuthProvider/AuthProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","useBilling"] */ "/vercel/path0/packages/app/src/components/BillingProvider/BillingProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","useDispatchAction"] */ "/vercel/path0/packages/app/src/components/DispatchActionProvider/DispatchActionProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/I18nProvider/I18nProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","useMainMenu"] */ "/vercel/path0/packages/app/src/components/MainMenuProvider/MainMenuProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","useOnboarding"] */ "/vercel/path0/packages/app/src/components/OnboardingProvider/OnboardingProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/app/src/components/PermissionsProvider/PermissionsProviderClient.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","usePrivilegeEscalation"] */ "/vercel/path0/packages/app/src/components/PrivilegeEscalationProvider/PrivilegeEscalationProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","useToastNotification"] */ "/vercel/path0/packages/app/src/components/ToastNotificationProvider/ToastNotificationProvider.jsx");
